.testimonial-section {
    background-image: url(../../images/home/gradient-bg.webp);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 70px 50px;
    padding-bottom: 150px;
}

.testimonial-section .card-height {
    min-height: 560px;
    max-height: 560px;
}

.testimonial-img-div {
    position: absolute;
    top: 0px;
}

.pt-50 {
    padding-top: 50px;
}

.mt-50 {
    margin-top: 50px;
}

.testimonial-img {
    width: auto;
    border-radius: 50%;
    right: -25px;
    position: relative;
}

.testimonial-carousel .slick-dots li button:before {
    color: #224F89;
    font-size: 13px;
    line-height: 13px;
    opacity: 1;
}

.testimonial-carousel .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #fff;
}

.testimonial-carousel .slick-dots {
    top: 100%;
    bottom: unset;
    margin-top: 70px;
}

.portfolio-carousel-section {
    background-image: url(../../images/carousel-gradient-bg.webp);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 70px 50px;
    padding-bottom: 150px;
}

.portfolio-carousel-section .slick-next, 
.portfolio-carousel-section .slick-prev {
    margin-top: 30px;
    top: 100%;
    bottom: 0;
    width: auto;
    height: auto;
}

.portfolio-carousel-section .slick-next {
    right: 10px
}

.portfolio-carousel-section .slick-prev {
    right: 95px;
    left: unset;
}

.portfolio-title {
    font-family: var(--font-family);
    font-size: 56px;
    font-weight: 700;
    line-height: 70px;
    text-transform: uppercase;
}

.slick-slide {
    outline: none;
}

.details-block {
    max-width: 600px;
}
.brand-urls{
    word-break: break-all;
}

.testimonial-carousel .content-block{
    margin-top: 50px;
}
.portfolio-light-carousel .slick-next,.portfolio-light-carousel .slick-prev {
    bottom: 0;
    height: auto;
    width: auto;
    margin-top: 0;
    top: unset;
}
.portfolio-light-carousel .slick-prev{
    left: auto;
    right: 95px;
}
.portfolio-light-carousel  .slick-next {
    right: 10px;
}

.portfolio-section .portfolio-light-carousel.portfolio-items{
    margin-bottom: 60px;
    padding: 0 10px;
}
.portfolio-section .portfolio-light-carousel .portfolio-item{
    padding:10px
}



@media(max-width:992px) {
    .portfolio-title {
        font-size: 24px;
        line-height: 36px;
    }

    .top-text{
        font-size: 9px;
        line-height: 12px;
    }

    .testimonial-section,
    .portfolio-carousel-section {
        padding: 35px 10px;
    }
    .portfolio-carousel-section,.testimonial-section{
        padding-bottom: 100px;
    }
    .testimonial-carousel .slick-dots{
        bottom: 0;
        top: 100%;
        margin: unset;
        margin-top: 40px;
    }

    .portfolio-carousel-section .slick-next,
    .portfolio-carousel-section .slick-prev {
        margin-top: 45px;
    }
    .testimonial-img{
        width: 66px;
        height: 66px;
        top: 15px;
    } 
    .testimonial-carousel .content-block{
        margin-top: 20px;
    }
    .testimonial-carousel .content-block h6{
        font-size: 10px;
        line-height: 12px;
    }
    .testimonial-carousel .content-block h4{
        font-size: 14px;
        line-height: 16px;
    }
    .testimonial-section .card-height {
        min-height: 230px;
        max-height: 230px;
    }
    .portfolio-light-carousel .slick-next,.portfolio-light-carousel .slick-prev {
        bottom: 0;
        margin-top: 30px;
        top: 100%;
    }
    .portfolio-light-carousel .portfolio-img{
        height: 300px;
    }
}

@media(max-width:768px){
    .portfolio-light-carousel .portfolio-img{
        height: 200px;
    }
}