/* hero.css */
.hero-wrapper {
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        url(../../images/home/hero.webp) center/cover no-repeat;
    padding: 123px 20px;
}

.title-wrapper {
    max-width: 1046px;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 37px 44px;
}

.hero-wrapper .section-subtitle,
.hero-wrapper .section-title {
    font-family: var(--font-family);
    color: var(--secondary-color);
    text-align: center;
    text-transform: uppercase;
    letter-spacing: -0.01em;
}

.hero-wrapper .section-subtitle {
    font-size: 32px;
    font-weight: 400;
    line-height: 38.73px;
    margin-bottom: 10px;
}

.hero-wrapper .section-title {
    font-size: 64px;
    font-weight: 600;
    line-height: 77.45px;
    margin-bottom: 0;
}

/* Explore Offerings Section */
.explore-offerings-wrapper .title-wrapper {
    background-color: transparent;
}

.offerings-section {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    margin-bottom: 28px;
}

.solution-item {
    flex: 1;
    text-align: center;
    padding: 10px;
    border-left: 1px solid var(--primary-color);
}

.solution-item:first-child {
    border-left: none;
}

.solution-item img {
    max-width: 100%;
    height: auto;
    margin-bottom: 40px;
}

.explore-offerings-wrapper {
    padding: 48px;
}

.brands-section {
    max-width: 1073px;
    margin: auto;
}

.brand-logos-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
    row-gap: 30px;
}

.brand-logos-row img {
    max-width: 100%;
    height: auto;
}

/* Other Sections */
.technical-competency-wrapper,
.product-partners-wrapper {
    padding: 48px;
}

.product-partners-wrapper .container-fluid {
    max-width: 1200px;
}

.schedule-call-wrapper {
    padding: 30px 0;
    background: url(../../images/home/schedule-call-bg.webp) center/cover no-repeat;
}

.schedule-call-wrapper .container-fluid {
    max-width: 1440px;
}

.schedule-call-wrapper .no-gutters {
    margin: 0;
}

.vertical-divider {
    border-right: 2.64px solid rgba(115, 115, 115, 0.2);
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
}

.link-wrapper {
    max-width: 1440px;
    margin: auto;
    padding: 20px;
}

.company-insights-section {
    background: url(../../images/home/gradient-bg.webp) center/cover no-repeat;
}

.border-gradient {
    border-bottom: 1px solid #10243E;
    border-image: linear-gradient(90deg, #FFFFFF 0%, rgba(153, 153, 153, 0.1) 90%);
    border-image-slice: 1;
}

.clutch-card {
    border: 1px solid #DDD9D9;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 20px;
    max-width: 400px;
    margin: auto;
}

.count-block {
    padding: 80px 20px;
}

.product-partners-wrapper h1 {
    font-size: 32px;
    line-height: 40px;
}

.product-partners-wrapper img {
    max-width: 150px;
}

/* Responsive Styles */
@media (max-width: 992px) {
    .hero-wrapper {
        padding: 25px 12px;
    }

    .title-wrapper {
        padding: 17px 12px;
    }

    .company-insights-section .big-title {
        font-size: 20px;
        line-height: 24px;
    }

    .technical-competency-wrapper,
    .product-partners-wrapper {
        padding: 30px 10px;
    }

    .schedule-call-wrapper {
        padding: 30px 0;
    }

    .count-block {
        padding: 40px 20px;
    }

    .product-partners-wrapper h1 {
        font-size: 24px;
        line-height: 30px;
    }

    .product-partners-wrapper img {
        max-width: 100%;
    }
}

@media (max-width: 767.5px) {
    .hero-wrapper .section-subtitle {
        font-size: 12px;
        line-height: 14px;
    }

    .hero-wrapper .section-title {
        font-size: 22px;
        line-height: 26px;
    }

    .solution-item {
        flex: 0 1 45%;
        border: none;
    }

    .explore-offerings-wrapper {
        padding: 30px 10px;
    }

    .link-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .offerings-section {
        padding-bottom: 0;
        flex-wrap: wrap;
    }
}

@media (min-width: 768px) {
    .hero-wrapper .section-subtitle {
        font-size: 24px;
    }

    .hero-wrapper .section-title {
        font-size: 42px;
    }

    .link-wrapper {
        display: flex;
        justify-content: space-between;
    }
}

@media (min-width: 1200px) {
    .hero-wrapper .section-subtitle {
        font-size: 28px;
    }

    .hero-wrapper .section-title {
        font-size: 48px;
    }

    .schedule-call-wrapper .container-fluid {
        max-width: 1440px;
    }
}
