:root {
  --font-family: "Inter", sans-serif;
  --primary-color: #10243E;
  --secondary-color: #FFFFFF;
  --base-font-size: 20px;
  --small-font-size: 16px;
  --sm-medium-font-size: 18px;
  --medium-font-size: 20px;
  --lg-medium-font-size: 22px;
  --large-font-size: 36px;
  --xl-font-size: 48px;
}

body {
  font-size: var(--base-font-size);
  font-family: var(--font-family);
}

h1 {
  font-size: var(--large-font-size);
}

h2 {
  font-size: var(--base-font-size);
}

h3 {
  font-size: var(--lg-medium-font-size);
}

h4 {
  font-size: var(--medium-font-size);
}

h5 {
  font-size: var(--sm-medium-font-size);
}

h6 {
  font-size: var(--small-font-size);
  line-height: 27px;
}

.big-title {
  font-size: var(--xl-font-size);
}

p,
a {
  font-size: var(--base-font-size);
}

.ft-wt-400 {
  font-weight: 400;
}

.ft-wt-500 {
  font-weight: 500;
}

.ft-wt-600 {
  font-weight: 600;
}

.opacity-70 {
  opacity: 0.7;
}

.text-primary{
  color: var(--primary-color)!important;
}

.mb-120{
  margin-bottom: 120px;
}

.bg-primary{
  background-color: var(--primary-color)!important;
}

.ft-16{
  font-size: var(--small-font-size);
  line-height: 27px;
}

.ft-28{
  font-size: 28px;
  line-height: 36px;
}

.btn {
  border-radius: 4px;
  border: none;
  height: 48px;
  font-family: var(--font-family);
  font-weight: 600;
  padding: 12px 24px;
}

.main-section{
  margin-top: 98px;
}

.ft-12{
  font-size: 12px;
  line-height: 16px;
}

.container-fluid {
  max-width: 1440px;
}



.btn-primary,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}


.btn-secondary,
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:focus {
  background-color: var(--secondary-color);
  color: var(--primary-color);
}

a {
  text-decoration: none;
  color: #fff;
}

.navbar-brand {
  cursor: pointer;
  padding: 0;
}

.mt-120 {
  margin-top: 120px;
}

.mt-116{
  margin-top: 116px;
}

.border-end {
  border-right: 1px solid #D7CDCD!important;
}


.accordion-button:focus{
  box-shadow: none;
}
.faq-section .accordion-button{
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  padding: 18.5px 20px;
  color: #5E5E5E;
}
.faq-section .accordion-body {
  font-size: var(--small-font-size);
  color: var(--primary-color);
}
.faq-section .accordion{
  max-width: 990px;
  margin: auto;
}
.faq-section{
  background: rgba(16, 36, 62, 0.05);
}

::-webkit-scrollbar {
  width: 5px;
}


::-webkit-scrollbar-track {
  background: transparent;
}


::-webkit-scrollbar-thumb {
  background: #a5a5a57d;
}


::-webkit-scrollbar-thumb:hover {
  background: #a5a5a57d;
}


.navbar-toggler:focus{
  box-shadow: none;
}

.mb-30{
  margin-bottom: 30px;
}

.invalid-feedback{
  position: absolute;
  font-size: 12px;
  line-height: 16px;
  font-family: var(--font-family);
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn{
  background-color: #8d8d8d;
  cursor: not-allowed;
}

.error-img-block{
  max-width: 400px;
  margin: auto;
}

.cursor-pointer{
  cursor: pointer;
}

@media (max-width: 991.5px) {
  :root {
    --base-font-size: 16px;
    --small-font-size: 14px;
    --sm-medium-font-size: 16px;
    --medium-font-size: 18px;
    --lg-medium-font-size: 20px;
    --large-font-size: 20px;
    --xl-font-size: 40px;
  }
  .btn{
    padding: 12px 10px;
    height: auto;
    width: 100%;
  }
  .big-title{
    font-size:20px;
    line-height: 24px;
  }
  .ft-28{
    font-size: 18px;
    line-height: 24px;
  }
  
}

@media (min-width: 992px) {
  :root {
    --base-font-size: 20px;
    --small-font-size: 16px;
    --sm-medium-font-size: 18px;
    --medium-font-size: 20px;
    --lg-medium-font-size: 22px;
    --large-font-size: 36px;
    --xl-font-size: 42px;
  }
}

@media (min-width: 1200px) {
  :root {
    --base-font-size: 20px;
    --small-font-size: 16px;
    --sm-medium-font-size: 18px;
    --medium-font-size: 20px;
    --lg-medium-font-size: 22px;
    --large-font-size: 36px;
    --xl-font-size: 48px;
  }
}

@media(max-width: 1200px){
  .main-section{
    margin-top: 79.6px;
  }
}