.contact-form-section .form {
    max-width: 1104px;
    margin: auto;
}

.contact-form-section input.form-control,
.contact-form-section select.form-control {
    width: 100%;
    height: 56px;
    padding: 16px 12px;
    border-radius: 4px;
    border: 1px solid #E0E0E0;
    box-shadow: none;
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    text-align: left;
}

.contact-form-section .react-tel-input .form-control {
    width: 100%;
    height: 56px;
    border-radius: 4px;
    border: 1px solid #E0E0E0;
    box-shadow: none;
    padding-left: 48px;
    font-family: var(--font-family);
}

.contact-form-section .react-tel-input .form-control::placeholder {
    color: #919191;
}

.contact-form-section textarea.form-control {
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    text-align: left;
}

.contact-form-section input.form-control::placeholder {
    color: #5E5E5E;
    font-weight: 600;
}

.contact-form-section .react-tel-input .flag-dropdown {
    background-color: #fff;
    border: 1px solid #E0E0E0
}

.contact-form-section form {
    max-width: 1104px;
    margin: auto;
}

.contact-form-section .card-block {
    border: 1px solid #DBD7D7;
    box-shadow: 0px 4px 4px 0px #00000040;
    padding: 36px 46px;
    border-radius: 24px;
    max-width: 435px;
    margin: auto;
}

.contact-form-section .card-block .card-title {
    font-size: 22.86px;
    font-weight: 600;
    line-height: 28.58px;
    text-align: left;
}

.contact-form-section .card-block .card-subtitle {
    font-size: 11.43px;
    font-weight: 500;
    line-height: 15.72px;
    text-align: left;
}

.phone-error {
    margin-top: 0.25rem;
    font-size: 12px;
    line-height: 16px;
}

.alert{
    font-size: 16px;
    padding: 10px;
    background-color: transparent;
    border: none;
    text-align: center;
    font-weight: 600;
}
.alert-danger{
    color: #d00016;
}
.alert-success{
    color: #009350;
}
.info-btn{
    position: absolute;
    right:100%;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
}

@media(max-width:992px) {
    .contact-form-section .card-block {
        padding: 20px;
    }
    
}