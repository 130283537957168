.video-container {
    position: relative;
    width: 100%;
    max-width: 1000px;
    margin: auto;
    cursor: pointer;
  }
  
  .video-thumbnail {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .acube-video.video-container{
    max-width: 1200px;
  }

  .office-video-container {
    position: relative;
    width: 100%;
    max-width: 1000px;
    margin: auto;
    cursor: pointer;
  }

  .responsive-iframe {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
    height: 0;
}

.responsive-iframe iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Container for the responsive iframe */
.responsive-iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio (height/width) */
  height: 0;
  overflow: hidden; /* Ensure the overlay doesn't affect the layout */
}

/* Style for the iframe itself */
.responsive-iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0; /* Remove default border */
}

/* Style for the loader overlay */
.custom-loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10; /* Ensure overlay is on top */
  pointer-events: none; /* Prevent overlay from blocking interactions */
  transition: opacity 0.3s ease; /* Smooth fade-out effect */
}

/* Style for the spinner */
.custom-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #007bff;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

/* Keyframes for spinner animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
